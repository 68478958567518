<template>  
<div class="left-block">
                  <div class="userheader">
                    <div style="
                        color: #00b0f0;
                        margin-left: 15px;
                        font-weight: 500;
                        font-size: 24px;
                      ">
                      个人中心
                    </div>
                    <div @click="logout" style="
                        color: #d03050;
                        margin-right: 15px;
                        cursor: pointer;
                        font-size: 14px;
                      ">
                      退出登录
                    </div>
                  </div>
                  <div class="icon-block">
                    <div v-if="nickname" style="
                        line-height: 30px;
                        font-size: 18px;
                      ">
                      {{nickname}}
                    </div>
                    <div v-else style="
                        line-height: 30px;
                        font-size: 18px;
                      ">
                     未命名
                    </div>
                    <div style="
                        line-height: 30px;
                        font-size: 14px;
                        color: #95aac9;
                      ">
                      <!-- username.@utoocom.com -->
                      暂无
                    </div>
                    <div style="
                        text-align: left;
                        line-height: 30px;
                        font-size: 14px;
                        color: #555555;
                      ">
                      领先的AI工具，让你的创作更加轻松。支持多种风格，让你的创作更加丰富。
                    </div>
                  </div>
                  <div class="textList" style="text-align: left">
                    <div class="texttitle">我的剩余额度</div>
                    <div class="textitem">
                      <span class="textName">常用AI模型:</span>
                      <span class="textNum">{{num}}</span>次
                    </div>
                    <!-- <div class="textitem">
                      <span class="textName">增强AI模型:</span>
                      <span class="textNum">3</span>次
                    </div> -->
                    <!-- <div class="textitem">
                      <span class="textName">常用AI模型Token:</span>
                      <span class="textNum">3</span>Token
                    </div> -->
                    <!-- <div class="textitem">
                      <span class="textName">增强AI模型Token:</span>
                      <span class="textNum">3</span>Token
                    </div> -->
                    <div class="textitem">
                      <span class="textName">绘画使用额度:</span>
                      <span class="textNum">*</span>次
                    </div>
                    <!-- <div class="guoquTime">
                      会员过期时间：<span>2024-07-09</span>
                    </div> -->
                  </div>
                </div>

</template>  
  
<script>  
export default {  
  data() {  
    return {  
      nickname:null,
      num:null,
      show: false,  
      uid:null,
      mainurl:'http://113.200.114.168:4001',
    };  
  },  
  created(){
    let userinfo =JSON.parse(localStorage.getItem('userinfo'))
    this.nickname=userinfo.nickname
    this.uid=userinfo.uid
    this.check_credits();
  },
  methods: {  
    // /check_credits
    logout() {
      alert("退出了");
    },
    check_credits(){
      this.get(this.mainurl+"/api/check_credits",{userid:this.uid}).then((res) => {
         console.log(res)
        this.num = res.data;
        // this.agentList = arr;
      });
    }
    // handleOpen(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // handleClose(key, keyPath) {
    //   console.log(key, keyPath);
    // },
  },  
};  
</script>  
  
<style scoped>  
.userheader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.textList {
  margin-top: 30px;
}
.texttitle {
  line-height: 30px;
  font-size: 20px;
  color: #409eff;
  padding-left: 20px;
}
.textitem {
  padding-left: 30px;
  font-size: 14px;
  line-height: 40px;
}
.textName {
  color: #409eff;
}
.textNum {
  color: #333693;
  margin-left: 20px;
  margin-right: 8px;
}

</style>