<template>
  <div class="fillcontain">
      <!-- <div class="header" style="width: 100%; ">
        <div style=" height: 60px; padding-left:20px;">
          UOTO AI CENTER
        </div>
      </div> -->
      <div class="container">
        <div  class="col-left">
          <div style="" class="leftox">
            <div class="el-menu-vertical-demo">
              <div class="logo">
                <img src="../assets/logo.png" alt="" />
              </div>
              <div class="mian">
                <div  
                  v-for="(item, index) in menuItems"  
                  :key="index"  
                  :class="['menuItem', { active: activeIndex === index }]"  
                  @click="menuClick(item.path, index)"  
                >  
                  <a>  
                    <i :class="item.icon"></i>  
                    <span class="title">{{ item.title }}</span>  
                  </a>  
                </div>  
              
              </div>

              <div class="user" @click="dialogVisible = true">
                <a>
                  <img v-if="headicon" :src="headicon"  />
                  <img v-else src="../assets/logo.png"  />
                  <!-- <span class="title">个人中心</span> -->
                </a>
              </div>
              <el-dialog :visible.sync="dialogVisible" width="300px" :modal="false" :before-close="handleClose">
                <user></user>
              </el-dialog>
            </div>
          </div>
        </div>
        <div class="col-rifght" >
          <div class="rifghtbox">
            <keep-alive>
              <transition name="slide-fade">
                <router-view class="homeRouterView" />
              </transition>
            </keep-alive>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import user from '@/components/user.vue';
export default {
  components: {  
    user  
  },
  name: "Home",
  data() {
    return {
      uid:'',
      nickname:'',
      headicon:null,
      dialogVisible: false,
      activeIndex: 0, // 假设初始激活的是第一个菜单项  
      menuItems: [  
        { path: '/index', icon: 'el-icon-s-comment', title: 'AI问答' },  
        { path: '/knowledge', icon: 'el-icon-box', title: '知识库' },  
        // { path: '/engine', icon: 'el-icon-zoom-in', title: '网络' },  
        { path: '/agent', icon: 'el-icon-s-platform', title: 'Agent' },
        { path: 'https://www.zfuo2o.com/#/', icon: 'el-icon-s-home', title: 'home' },

        // { path: '/draw', icon: 'el-icon-picture', title: 'AI绘画' },  
      ], 
    };
  },
  created(){
    let userinfo =JSON.parse(localStorage.getItem('userinfo'))
    this.uid=userinfo.uid
    this.nickname=userinfo.nickname
    this.headicon=userinfo.headicon
    let path=this.$route.path
    const index = this.menuItems.findIndex(item => item.path === path)
    this.activeIndex=index
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      this.dialogVisible = false;
    },
    clickFold() {
      this.isCollapse = !this.isCollapse;
    },
    menuClick(path,index) {
      // console.log(path)
      // console.log(index)
      this.activeIndex = index;
      if(index==3){
        window.location.href=path
      }else{
        this.$router.push(path);
      }
    },   
    
  },
  computed: {
    defaultActive: function () {
      // console.log(this.$route.path)
      return this.$route.path;
      // return this.$route.path.replace("/", "/index");
    },
  },
};
</script>
<style scoped>
.fillcontain {
  height: 100%;
  width: 100%;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  
}
.col-left{
  width: 4%;
  /* background-color: #222324; */
  min-height: 100vh;
  border-right: 1px solid #d9d9d9;
  min-width:70px;
 
}
.col-rifght{
  width: 96%;
  min-height: 100vh;
}
.leftox{
  background-color: aliceblue; height: 100vh; z-index: 999
}
.rifghtbox{
  height: 100vh
}

.header {
  color: #222324;
  background-color: aliceblue;
  font-size: 24px;
  font-weight: bold;
  line-height: 50px;
}

.el-menu-vertical-demo {
  /* border-right: solid 1px #222324; */
  /* padding: 10px 5px; */
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 60px;
  border-right: 1px solid #d9d9d9;
  z-index: 999;
}
.logo {
  width: 60px;
  height: 100px;
  /* border-bottom:1px solid #333639; */
}
.logo img {
  width: 45px;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  display: block;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
}
.mian {
  /* margin-top:20px; */
  flex: 1 1 0%;
}
.menuItem {
  display: block;
  width: 100%;
  height: 60px;
  color: #333639;
}
.menuItem a {
  /* width: 50px; */
  height: 60px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  /* color: #333639; */
  cursor: pointer;
}
.menuItem a i {
  display: block;
  font-size: 24px;
}
.menuItem a .title {
  display: block;
  text-align: center;
}
.active {
  color: rgb(64, 143, 218);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.icon-block {
  /* width:100%; */
  margin-top: 10px;
  padding: 8px 30px;
}
.icon-block img {
  display: block;
  width: 150px;
  height: 150px;
  text-align: center;
  margin: 0 auto;
}

.guoquTime {
  color: #ff0000;
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  margin-top: 30px;
}
.el-menu-vertical-demo /deep/.el-dialog {
  margin-left: 80px;
}
@media (max-width: 640px) {
  .container{
    width:100%;
    height:100%;
    display:block;
  }
  
.col-left{
  width:100%;
  min-height:60px;
  position: absolute;
  bottom: 0;
  left:0;
  z-index:999;

  }

  .leftox{
    height:10vh;
    min-height:60px;
  }
.el-menu-vertical-demo{
  flex-direction:row;
  min-height:60px;

}
.mian{
  display:flex;
  /* flex:none; */
  /* flex-grow:0 */
}
.menuItem{
  width: 25%;
}
.logo{
  display: none;
}
.user{
  display: none;
}
.col-rifght{
  width:100%;
  height: 90vh; 
  overflow: auto;
  min-height:90vh;
  position: absolute;
  top:0;
}
.rifghtbox{
  height: 90vh; 
}

}

</style>
