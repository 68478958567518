<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    // this.userid=this.$route.query.userid
    // this.nickname=this.$route.query.nickname
    // this.headicon=this.$route.query.headicon
    // let userinfo={uid:this.$route.query.userid,nickname:this.$route.query.nickname,headicon:this.$route.query.headicon}
    // localStorage.setItem('userinfo',JSON.stringify(userinfo))
  },
 



}

</script>


<style>
 html,body{
  margin:0;
  padding:0;
  min-height:100%;
  width:100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width:100%;
height:100%;

}
.user {
  display: flex;
  padding: 24px 0;
  justify-content: center;
}
.user a {
  display: block;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
}
.user a img {
  width: 100%;
  height: 100%;

  display: block;
  text-align: center;
}
@media (max-width: 640px) {
  .user a {
  width: 30px;
  height: 30px;
}
.user {
  padding: 8px 0;
}
}

</style>
