<template>
  <div class="wdcontainer" ref="wdcontainer">
    <div class="right-panel">
      <div class="right-content">
        <!-- 右侧内容 -->
        <div class="rightblock">
          <div class="header">
            <div>
              <div @click="drawer = true" type="primary" style="font-size:26px" class=" el-icon-menu">
              </div>
            </div>
          <div class="user" @click="dialogVisible = true">
                <a>
                  <img v-if="headicon" :src="headicon"/>
                  <img v-else src="../assets/icon.png"  />
                </a>
              </div>
          </div>
         
          
          <div class="scrollRef">
            <div class="mainContent" ref="scrollContainer">
              <!-- <div v-if="chatMessages.length == 0" class="welcome">
                <h1>知识库</h1>
                <h4>
                  作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑。
                </h4>
                <h4>你可以试着问我问题哦！</h4>
              </div> -->
              <div  ref="chatContainer" class="chatContainer">
                <div
                  v-for="(item, index) in chatMessages"
                  :key="index"
                  class="message"
                >
                  <div v-if="item.from === 'user'" class="user-message">
                    <div class="userdiv talkitem">
                      <div class="talkUser ">
                        <img v-if="headicon" :src="headicon"  />
                        <img v-else src="../assets/kefu.png"  />
                      </div>
                      <div class="talkContent" v-html=item.text > </div>
                    </div>
                   
                  </div>
                  <div v-else-if="item.from === 'bot'" class="bot-message">
                    <div class="ai talkitem">
                      <div class="talkUser">
                        <img src="../assets/kefu.png" alt="" />
                      </div>
                      <div class="talkContent">
                        <span v-html=item.text class="text"></span>
                        <!-- <div v-for="it in item.text.docs" :key="it" class="tipsblock">
                          <div v-html="it" class="tips"></div>
                        </div> -->
                      </div>
                    </div>
                    <!-- <div class="siteBlock">
                      <span class="el-icon-document-copy "  @click="copyText(item.text)">复制</span> 
                    </div>                    -->
                  </div>
                  <div v-else class="bot-message">
                    <div class="ai talkitem">
                      <div class="talkUser "><img src="../assets/icon.png" alt=""></div>
                      <div class="talkContent">
                        <div v-html="item" class="text"></div>
                       
                      </div>
                    </div>
                    <div class="siteBlock">
                      <span class="el-icon-document-copy "  @click="copyText(item)">复制</span> 
                    </div> 
                  </div>
                </div>
              </div>
               <!-- <div class="stop" v-show="isStop">
                <span  @click="reconnectSSE">停止生成</span>
               </div>-->
              <div>
                <loading ref="loading"></loading> 
               </div>
            </div>
          </div>
          <div class="code-block-footer">
            <div class="aiIcon">
              <img src="../assets/icon.png" alt="" />
            </div>
            <el-input
              type="textarea"
              placeholder="可以问我问题......（shift + 回车 = 换行）"
              v-model="question"
              @keyup.native="handleKeyup"
            ></el-input>
            <el-button
              type="primary"
              icon=" el-icon-s-promotion"
              size="mini"
              @click="sendMessage"             
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import loading from '@/components/loading.vue'; 
import user from '@/components/user.vue';
export default {
  components: {  
    loading,user
  },
  data() {
    return {
      dialogVisible: false,
      drawer: false,
      messages: [],
      knowledgeValue: "",
      knowledgeName: "",
      formInline: {
        historyKey: "",
      },
      fileNameList:[],
      isLeftHidden: false, // 控制左侧面板是否隐藏的变量
      options: [],
      modeValue: "0",
      question: "",
      chatMessages: [
        {
          from: "bot",
          text: '您好，欢迎使用智能客服;您可以问我问题，例如：</br>1.网络资费；</br>2.公司业务；</br>3.天空第一体化；',
        },
      ],
      nickname:'',
      headicon:null,
      mainurl:'http://113.200.114.168:4001',
      // mainurl:'https://ai.uotocom.com',
      data:{
        userid:'99999',
        query: '',
        knowledge_base_name: 'uoto_ai_kefu',
        top_k: 3,
        score_threshold: 1,
        history: [],
        stream: true,
        model_name: "mixtral-local",
        temperature: 0.1,
        max_tokens: 3000,
        prompt_name: "default",
      }
    };
  },
  
  created() {
    // let userinfo =JSON.parse(localStorage.getItem('userinfo'))
    // this.uid=userinfo.uid
    // this.nickname=userinfo.nickname
    // this.headicon=userinfo.headicon
    // this.data.userid=userinfo.uid
    // this.getList();
    // this.getHistory();
    this.$nextTick(()=>{
    let box=this.$el.querySelector(".mainContent")
    box.scrollTop=box.scrollHeight;
    })
  },
  methods: {
    handleKeyup(event){
      // console.log(event.keyCode)
    // 检查是否按下了回车键  
    if (event.key === 'Enter' || event.keyCode === 13) {  
            // 检查是否同时按下了 Shift 键  
            if (!event.shiftKey) {  
              // 阻止默认行为（换行）  
              event.preventDefault();  
              this.sendMessage();  
            }  
            // 如果按下了 Shift + Enter，则不需要阻止默认行为，直接换行  
          }  

    },
    sendMessage() {
      if (!this.question) {
        this.$message({
          showClose: true,
          message: '你还没有输入任何内容！',
          type: 'error'
        });
        return
      };   
      // this.data.knowledge_base_name=this.knowledgeName
      this.data.query=this.question
      this.chatMessages.push(
        {
          from: "user",
          text: this.question,
        },
        {
          from: "bot",
          text: "",
        }
      );
      this.question=''
      this.$nextTick(() => {  
        // 滚动到最新消息  
        const chatContainer = this.$refs.chatContainer;  
        chatContainer.scrollTop = chatContainer.scrollHeight; 
      });  
      let _this=this
      fetch("https://ai.uotocom.com/api/chat/knowledge_base_chat", {
      // fetch("http://113.200.114.168:4001/api/chat/knowledge_base_chat", {
        method: "POST",
        body: JSON.stringify(this.data),
        headers: {
          "Content-Type": "application/json",
        },
      }) // 表示流式接口的url
        .then((response) => {
          const reader = response.body.getReader();
           // 滚动到最新消息  
      
          const decoder = new TextDecoder('utf-8');
          const stream = new ReadableStream({
            start(controller) {
              function push() {
                reader.read().then(({ done, value }) => {
                  // controller.enqueue(value);
                  let text = decoder.decode(value)
                  // console.log(text)
                  if(text.includes('ping')) {  
                      // continue;  
                  }else{
                  let lines = text.split("\n")
                  for (let it of lines) {
                    if (it.length > 6) {
                      let jsonStr = it.substring(6)
                      jsonStr = JSON.parse(jsonStr);
                      console.log(jsonStr);
                      if(jsonStr.answer){
                        _this.chatMessages[_this.chatMessages.length - 1].text += jsonStr.answer;
                        _this.$nextTick(() => {  
                          const chatContainer = _this.$refs.chatContainer; 
        chatContainer.scrollTop = chatContainer.scrollHeight; 
      });  
                      }
                      if(jsonStr.docs){
                        _this.chatMessages[_this.chatMessages.length - 1].text += jsonStr.docs[0];
                        _this.$nextTick(() => {  
                          const chatContainer = _this.$refs.chatContainer; 
        chatContainer.scrollTop = chatContainer.scrollHeight; 
      });  
                       
                      }
                    }
                  }
                }
                  // .replace(/[\r|\n|\t]/g, "");
                  if (done) {
                    controller.close();
                    let str=_this.chatMessages[0].text
                    if (str.length > 14) {  
                      str=str.substring(0, 14)
                    }
                  // let filename =_this.uid+"_"+ str+"_knowledge"
                  // let content = JSON.stringify(_this.chatMessages)
                  // _this.addHistory(filename,content)
                    return;
                  }
                  // this.chatMessages=jsonStr
                  // console.log(this.chatMessages);
                  // this.userInput = "";
                  // this.$refs.loading.stop();
                  push();
                });
              }
              push();
            },
          });
          return new Response(stream, {
            headers: { "Content-Type": "application/json" },
          });
        })
        .then((response) => response.text())
        .catch((err) => console.error(err));
    
        },
    reconnectSSE() {
      console.log(this.eventSource)
      if (this.eventSource) {
        this.eventSource.close(); // 先关闭之前的连接
        this.eventSource = null;
      }
    },
    copyText(text) {
      // console.log(text)
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success('复制成功')
    })
      },
    //获取知识库名称列表
    getList() {
      this.get("/api/knowledge_base/list_knowledge_bases").then((res) => {
        //  console.log(res)
        var arr = res.data.data;
        this.knowledgeValue = arr[0];
        this.knowledgeName = arr[0];
        let arr2 = arr.map((v, index) => ({ value: index, label: v }));
        this.options = arr2.map(item => ({  
          ...item,  
          label: item.label.length > 20 ? item.label.slice(0, 20) + '...' : item.label,  
        }));  
        console.log(arr2)
        this.data.knowledgeName=arr[0]
      });
    },
    handleSelectChange(value) {
      this.knowledgeValue = value;
      this.knowledgeName = this.options[value].label;
      this.data.knowledgeName=this.options[value].label;
    },

    onSubmit() {
      this.getHistory()
      this.chatMessages =[]
    },
    onSearch() {},
    handleOpen(key, keyPath) {
      this.dialogVisible = true;
    },
    handleClose(key, keyPath) {
      this.dialogVisible = false;
    },
    //跳转对话页面
    create() {
      this.$router.push("knowledge");
    },
    //跳转知识库管理
    onSite() {
      this.$router.push("knowledgeSite");
    },
    // scrollToBottom() {
    //   this.$nextTick(() => {
    //   const scrollContainer = this.$refs.scrollContainer;
    //   if (scrollContainer) {
    //     scrollContainer.scrollTop = scrollContainer.scrollHeight;
    //     console.log(scrollContainer.scrollHeight)
    //   }
    // });
    // },
  },
};
</script>

<style scoped>
.wdcontainer {
  display: flex;
  height: 100vh; /* 占据视口高度 */
  overflow: hidden;
  width: 100%;
  position: relative;
  /* justify-content: center; */
}

.historyTitle {
  width:240px;
  text-align: center;
  font-size: 14px;
}
.historyList {
  width: 90%;
  padding: 10px 8px;
  height: 60vh;
}

.button-wrapper {
  position: absolute;
  top: 50%;
  right: -15px;
  /* text-align: center; 按钮居中显示   */
  /* margin: 20px 0; 上下间距   */
}
button {
  padding: 10px 20px;
  cursor: pointer;
}
button.toggled {
  background-color: #4caf50; /* 改变按钮背景颜色 */
  color: white; /* 改变按钮文字颜色 */
}
.historyItem{
height:30px;
border:1px solid #66B1FF;
line-height:30px;
border-radius: 5px;
padding:5px;
font-size:14px;
margin-top:5px;
width: 120px;  /* 设置固定宽度 */
  overflow: hidden;  /* 隐藏超出部分 */
  white-space: nowrap;  /* 防止文本换行 */
  text-overflow: ellipsis;
}
/* .historyItem span{
  width: 100px; 
  overflow: hidden;  
  white-space: nowrap; 
  text-overflow: ellipsis;
} */
.right-panel {
  /* display: flex; */
  /* flex-direction: column; */
  /* flex: 1; */
  /* flex-grow: 1; 自适应剩余空间   */
 
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: auto; /* 垂直滚动 */
  padding:10px;
 
}
.right-content {
  margin:0 auto;
  height: 100%;
   /* border:1px solid #66b1ff; */
   max-width:720px;
 
}
.chatContainer{
  height:600px;
  overflow-y: auto;  
}
.rightblock{
  height: 100%;
  overflow:auto;
}
.scrollRef {
  width: 100%;
  height: 100%;
  max-width: 1536px;
}
.mainContent {
  width: 100%;
  /* height: 100%; */
  /* overflow: hidden; */
  overflow-y: auto;
}

.padding-left {
  padding-left: 260px;
}
.code-block-head {
  margin: 0 auto;
}
.welcome {
  margin: 0 auto;
  text-align: center;
}
.welcome h1 {
  margin-top: 100px;
  text-align: center;
  color: #66b1ff;
}
.code-block-footer {
  /* position: fixed; */
  width: 100%;
  bottom: 0px;
  margin: 0 auto;
  padding-bottom: 70px;
  padding-top: 15px;
  background-color: rgb(255, 255, 255);
  z-index: 99;
  display: flex;
  justify-content:center;
  max-width:1500px;
}

.code-block-footer .aiIcon {
  width: 30px;
  height: 30px;
  border: 1px solid #dcdfe6;
  margin-right: 8px;
}
.code-block-footer .aiIcon img {
  width: 100%;
  height: 100%;
}
.el-textarea{
  width: 70%;
}
.code-block-footer /deep/ button {
  font-size: 20px;
  padding: 10px 15px;
  margin-left: 8px;
  height:40px;
}
.scrollRef{
  max-height:80vh;
  overflow-y: auto;
}
.message{
  margin-top:2%;
  margin-left:2%;
}
.talkitem{
  display: flex;
  margin-top:8px;
  align-items: flex-start;
}
.user-message .talkContent{
  background-color:#66b1ff;
  border-radius: 8px;
  padding: 5px 10px;
}
.bot-message .talkContent{
  background-color:#f0f8ff;
  border-radius: 8px;
  padding: 5px 10px;
}
.talkitem .talkUser{
  width:35px;
  height:35px;
  flex-shrink: 0;
}
.talkitem .talkContent{
  max-width:850px;
  /* height:50px; */
  text-align: left;
  margin-left:10px;
}
.talkitem .talkUser img{
  width:100%;
  height:100%;
}
.tipsblock{
  font-size:14px;
  margin-top:8px;
}
.talkitem .talkContent .text{
  white-space: pre-wrap;
}
.stop{
  margin-top:10px;
  padding-left:100px;
  font-size:13px;
  color:#66b1ff;
  text-align:left;
  width:70px;
}
.stop span{
  cursor: pointer;
}
.siteBlock{
  margin-top:10px;
  font-size:13px;
  color:#66b1ff;
  width:100%;
  text-align: right;
  max-width:836px;
}
.talkitem .talkContent .text{
  white-space: pre-wrap;
}
.siteBlock span{
  text-align: right;
  cursor: pointer;
}
.header{
  display: none;
}
@media (max-width: 640px) {
.wdcontainer{
  height:100vh;
  display:block;
  overflow-y: auto;
  border-bottom:1px solid #dcdfe6;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 15px;
  position: sticky;
  top: 0
}
.left-panel{
  display:none;
}
.padding-left {
    padding-left: 0px;
}
  .code-block-footer {
    /* position: fixed; */
    position: sticky;
    bottom: 0;
    width: 90%;
    padding: 0px;
    background-color: rgb(255, 255, 255);
    z-index: 9;
    display: flex;
}
/* .user {
  display: flex;
  padding:10px 0;
  justify-content: center;
} */
 ::v-deep .el-select-dropdown{
left:0 !important;
width: 100% !important;
}
#app >>> .el-select-dropdown{
left:0 !important;
max-width: 100% !important;
}
.el-select-dropdown /deep/ .el-select-dropdown__item {
/* left:0 !important; */
/* max-width: 100% !important; */
width:300px;
}
.el-dialog__wrapper /deep/.el-dialog {
  margin-top:4vh !important;
}
.el-textarea /deep/ textarea{
  height:75px;
}
.siteBlock{
  text-align: left;
}
.scrollRef{
  max-width:640px;
}
/* .historyList {
    height: 48vh;
} */
}
@media (max-height: 1400px) {
  .code-block-footer {
    padding-bottom: 0px;
    padding-top:5px;
}
}
@media (max-height: 700px) {
  .code-block-footer {
    padding-bottom: 10px;
    padding-top:5px;
}
}
@media (max-height: 600px) {
  .code-block-footer {
    padding-bottom: 15px;
    padding-top:5px;
}
}
@media (max-height: 550px) {
  .code-block-footer {
    padding-bottom: 25px;
    padding-top:5px;
}
}
@media (max-height: 500px) {
  .code-block-footer {
    padding-bottom: 30px;
    padding-top:5px;
}
}
@media (max-height: 450px) {
  .code-block-footer {
    padding-bottom: 35px;
    padding-top:5px;
}
}
@media (max-height: 250px) {
  .code-block-footer {
    padding-bottom: 40px;
    padding-top:5px;
}
}
</style>
