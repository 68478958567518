import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import { post } from "./utils/api.js";
import { get } from "./utils/api.js";
import { put } from "./utils/api.js";
import { deletefn } from "./utils/api.js";
Vue.config.productionTip = false
Vue.prototype.post = post
Vue.prototype.get = get
Vue.prototype.put = put
Vue.prototype.deletefn = deletefn
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
