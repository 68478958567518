import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import service from '../views/service.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'AI问答',
        component: () => import( '../views/index.vue')
      },     
      {
        path: '/knowledge',
        name: '知识库',
        component: () => import( '../views/knowledge.vue')
      },     
      {
        path: '/knowledgeSite',
        name: '知识库管理',
        component: () => import( '../views/knowledgeSite.vue')
      },     
      {
        path: '/agent',
        name: 'agent',
        component: () => import( '../views/agent.vue')
      },     
      // {
      //   path: '/engine',
      //   name: '网络',
      //   component: () => import( '../views/engine.vue')
      // },     
      // {
      //   path: '/draw',
      //   name: 'AI绘画',
      //   component: () => import( '../views/draw.vue')
      // },
       
    ]
  },
  // {
  //   path: '/',
  //   redirect: '/service',
  // },
  // {
  //   path: '/service',
  //   name: 'AI客服',
  //   component: () => import( '../views/service.vue')
  // },


]

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes
})

export default router
