<template>  
  <div v-if="show" class="loading-overlay">  
    <div class="loading-spinner demoItem">  
      <!-- 这里可以添加你自己的加载动画，比如使用SVG或者CSS动画 -->  
      <!-- <i class="fas fa-spinner fa-spin"></i>   -->
      <div class="container google-animation-9">
          <div class="shape shape-4">
            <div class="shape-4-top"></div>
            <div class="shape-4-bottom"></div>
            <div class="shape-4-eye"></div>
            <!-- <img src="./assets/images/christmasHat2.png" alt="christmasHat"> -->
          </div>
          <div class="shape shape-1"></div>
          <div class="shape shape-2"></div>
          <div class="shape shape-3"></div>
        </div>
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  data() {  
    return {  
      show: false,  
    };  
  },  
  methods: {  
    start() {  
      this.show = true;  
    },  
    stop() {  
      this.show = false;  
    },  
  },  
};  
</script>  
  
<style scoped>  
.loading-overlay {  
  width: 300px;  
  height: 60px;  
}  
.loading-spinner {  
  width: 300px;  
  height: 60px; 
}  
.demoItem {
  margin-left:300px;
}
 .demoItem .container .shape-1 {
  background-color: #1875E5;
}
.demoItem .container .shape-2 {
  background-color: #C5523F;
}
 .demoItem .container .shape-3 {
  background-color: #499255;
}
 .demoItem .container .shape-4 {
  background-color: #F2B736;
}
.google-animation-9 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.google-animation-9 .shape {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
}
.google-animation-9 .shape-1 {
  left: -5px;
  animation: Animation9Shape1 7s linear infinite;
}
.google-animation-9 .shape-2 {
  left: 15px;
  animation: Animation9Shape2 7s linear infinite;
}
.google-animation-9 .shape-3 {
  left: 35px;
  animation: Animation9Shape3 7s linear infinite;
}
.google-animation-9 .shape-4 {
  width: 30px;
  height: 30px;
  left: -40px;
  background-color: transparent!important;
  z-index: 2;
  animation: Animation9Shape4 7s linear infinite;
}
.google-animation-9 .shape-4 > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.google-animation-9 .shape-4 .shape-4-top {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FBBC05;
  clip: rect(0 30px 15px 0);
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  animation: Animation9Shape4Top 0.4s ease infinite alternate;
}
.google-animation-9 .shape-4 .shape-4-bottom {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FBBC05;
  clip: rect(15px 30px 30px 0);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  animation: Animation9Shape4Bottom 0.4s ease infinite alternate;
}
.google-animation-9 .shape-4 .shape-4-eye {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 10px;
}
@keyframes Animation9Shape4Top {
  0% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes Animation9Shape4Bottom {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes Animation9Shape4 {
  0% {
    left: -40px;
    transform: rotateY(0);
  }
  45% {
    left: 50px;
    transform: rotateY(0);
  }
  50% {
    left: 50px;
    transform: rotateY(180deg);
  }
  95% {
    left: -40px;
    transform: rotateY(180deg);
  }
  100% {
    left: -40px;
    transform: rotateY(0);
  }
}
@keyframes Animation9Shape1 {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  19% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes Animation9Shape2 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  22% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes Animation9Shape3 {
  0% {
    opacity: 1;
  }
  27% {
    opacity: 1;
  }
  29% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  64% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>