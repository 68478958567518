import axios from "axios";
import { Message } from "element-ui";
import router from "../router";

// 配置 请求响应拦截
axios.interceptors.response.use(
    (success) => {
        //业务逻辑错误1
        if (success.status && success.status == 200) {
            //浏览器接口响应正确
            if (
                success.data.code == 500 ||
                success.data.code == 401 ||
                success.data.code == 403
            ) {
                Message.error({ message: success.data.message });
                return;
            }
            if ( success.data.message ) {
                Message.success({ message: success.data.message });
            }
        }
        return success.data;
    },
    (error) => {
        //拦截服务器错误提示
        if (error.response.code == 504 || error.response.code == 404) {
            Message.error({ message: "服务器被吃了o(╯□╰)o" });
            return;
        } else if (error.response.code == 403) {
            Message.error({ message: "权限不足，请联系管理员！" });
            return;
        } else if (error.response.code == 401) {
            Message.error({ message: "尚未登录，请登录！" });
            // router.replace("/"); //没有登录，就跳转到默认页，默认页面是登录页。“、”代表路由router的index.js中的“/”的默认页。
            return;
        } else {
            //其他错误提示，不在判断中的错误
            if (error.response.data.message) {
                Message.error({ message: error.response.data.message });
            } else {
                Message.error({ message: "未知错误！" });
            }
        }
        return;
    }
);


//配置请求转发，解决跨域
//传送json格式的post请求
// export default {  
//     API_BASE_URL: 'https://api.example.com/v1/'  
//   };

const ConfigBaseURL = "https://ai.uotocom.com"; //默认路径，这里也可以使用env来判断环境
// const ConfigBaseURL = "http://113.200.114.168:4001"; //默认路径，这里也可以使用env来判断环境
// axios 配置
var instance = axios.create({
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    },
    timeout: 30000,
    baseURL: ConfigBaseURL, //接口请求地址
});

// 添加请求拦截器
// instance.interceptors.request.use(
//     (config) => {
//         // 在发送请求之前做些什么，比如传token
//         //如果存在token，请求携带token
//         if (window.sessionStorage.getItem("tokenStr")) {
//             config.headers["Authorization"] =
//                 window.sessionStorage.getItem("tokenStr");
//         }
//         return config;
//     },
//     (error) => {
//         // 对请求错误做些什么
//         console.log(error); // for debug
//         return Promise.reject(error);
//     }
// );

// 添加响应拦截器
// instance.interceptors.response.use(response => {
//     if (response.status === 200) {
//         return Promise.resolve(response);
//     } else {
//         return Promise.reject(response);
//     }
//     //对错误代码做处理

// }, error => {
//     // 对响应错误做点什么
//     if (error.response.status) {
//         switch (error.response.status) {
//             // 401: 未登录
//             // 未登录则跳转登录页面，并携带当前页面的路径
//             // 在登录成功后返回当前页面，这一步需要在登录页操作。
//             case 401:
//                 console.log("授权失败，请重新登录")
//                 break;
//                 // 403 token过期
//                 // 登录过期对用户进行提示
//                 // 清除本地token和清空vuex中token对象
//                 // 跳转登录页面
//             case 403:
//                 console.log("拒绝访问")
//                 break;
//                 // 404请求不存在
//             case 404:
//                 console.log("请求错误,未找到该资源")
//                 break;
//             case 500:
//                 console.log("服务器端出错")
//                 break;
//         }

//     } else {
//         console.log("连接服务器失败")
//     }
//     return Promise.reject(error);
// });
export default instance;
// post 请求方法
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        instance.post(url, data).then(
            (response) => {
                //对接口错误码做处理
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

// get 请求方法
export function get(url, data = {}) {
    return new Promise((resolve, reject) => {
        instance
            .get(url, {
                params: data,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
// put 请求方法
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        instance
            .put(url, {
                params: data,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
// delete 请求方法
export function deletefn(url, data = {}) {
    return new Promise((resolve, reject) => {
        instance
            .delete(url, {
                params: data,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
/**
 * 封装所有请求
 */
export function request(methed, url, data = {}, headers) {
    return new Promise((resolve, reject) => {
        instance({
            method: methed || "post",
            url: url,
            params: methed === "get" ? data : "",
            data: methed !== "get" ? data : "",
            headers: headers || { "Content-Type": "application/json;charset=UTF-8" },
        })
            .then((response) => {
                //对接口错误码做处理
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}


